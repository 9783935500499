<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :data-list="currentTabContent" @sort="onSort")
  .row
    .col-lg-12
      router-link(to='/')
        b-button.width-md.mb-2(variant="outline-secondary") 
          //- i.ri-arrow-left-fill.back-icon
          span 回上一層

      .card
        //- 大輪播圖
        b-tabs.tab-color(content-class v-model="tabIndex")
          b-tab(title="大輪播圖", active)
            .card-body
              FastImport(
                :region-type="1"
                :max-count="10" 
                :show-sub-type="true" 
                @import="onFastImportNewsList"
              )
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row  
                .col 
                  .card.col-lg-9(v-for="(item, index) of region01")
                    newsItem(
                      :key="'region01-NewsItemList-' + index + item.newsId"
                      :canUseAd="false"
                      :regionId="1" 
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="removeItem"
                    )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addItem(1)") ＋新增一則 （最多10則） 

          //- 焦點新聞
          b-tab(title="焦點新聞")
            .card-body
              FastImport(
                :region-type="2"
                :max-count="3" 
                :show-sub-type="true" 
                @import="onFastImportNewsList"
              )
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row  
                .col 
                  .card.col-lg-9(v-for="(item,index) of region02")
                    newsItem(
                      :key="'region02-NewsItemList-' + index + item.newsId"
                      :canUseAd="false"
                      :regionId="2" 
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="removeItem"
                    )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addItem(2)") ＋新增一則 （最多3則） 

          //- 右側新聞
          b-tab(title="右側新聞")
            .card-body
              FastImport(
                :region-type="3"
                :max-count="10" 
                :show-sub-type="true" 
                @import="onFastImportNewsList"
              )
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row 
                .col 
                  .card.col-lg-9(v-for="(item,index) of region03")
                    newsItem(
                      :key="'region03-NewsItemList-' + index + item.newsId"
                      :canUseAd="false"
                      :regionId="3" 
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="removeItem"
                    )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addItem(3)") ＋新增一則 （最多10則） 

        .row.m-2 
          .col
            b-button.action-btn.width-lg.mr-1(variant="primary" @click="saveRegionData" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
            b-button.action-btn.width-lg(variant="primary" @click="releaseAreaSetting" :disabled="releasing") {{ releasing ? '發佈中...' : '發佈' }}
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import newsItem from "@/components/news-item/news-item"
import Sort from "@/components/sort"
import FastImport from '@/components/FastImport'
import {
  checkFromData,
  formatRegionNewsList,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'

/**
 * Starter component
 */
export default {
  name: 'HomeRegion01',
  data () {
    return {
      title: "第一區",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "大首頁",
          href: "/",
        },
        {
          text: "第一區",
          active: true,
        }
      ],
      region01: [],
      region02: [],
      region03: [],
      tabIndex: 0,
      showSort: false,
      currentTabContent: [],
      saving: false,
      releasing: false
    };
  },
  watch: {
    tabIndex (_val) {
      console.log('=> tabIndex:', _val)
      this.getRegionArticleList(_val + 1)
    }
  },
  mounted () {
    this.getRegionArticleList()
  },
  computed: {
    showAddButton () {
      switch (this.tabIndex) {
        case 0:
          return this.region01.length < 10
        case 1:
          return this.region02.length < 3
        case 2:
          return this.region03.length < 10
      }
    }
  },
  methods: {
    // 增加一個新聞項目
    addItem (_regionId) {
      switch (_regionId) {
        case 1:
          this.region01.push({ ...RegionSettingSchema })
          break;
        case 2:
          this.region02.push({ ...RegionSettingSchema })
          break;
        case 3:
          this.region03.push({ ...RegionSettingSchema })
          break;
      }
    },
    // 移除項目
    removeItem (_index, _regionId) {
      switch (_regionId) {
        case 1:
          this.region01.splice(_index, 1)
          break;
        case 2:
          this.region02.splice(_index, 1)
          break;
        case 3:
          this.region03.splice(_index, 1)
          break;
      }
    },
    // 更新新聞項目資料
    setItemData (_setItem, _index) {
      console.log("=> 更新新聞項目資料:", _setItem)
      switch (this.tabIndex) {
        case 0:
          this.region01.splice(_index, 1, _setItem)
          break;
        case 1:

          this.region02.splice(_index, 1, _setItem)
          break;
        case 2:
          this.region03.splice(_index, 1, _setItem)
          break;
      }
    },
    // 獲得新聞項目清單
    getRegionArticleList (_regionId = 1) {
      let vm = this
      this.articleItems = false
      this.$root.apis.getAreaSetting(_regionId, function (_response) {
        const formatedItems = formatRegionNewsList(_response.body.data.news)

        switch (_regionId) {
          case 1:
            vm.region01 = formatedItems
            break
          case 2:
            vm.region02 = formatedItems
            break
          case 3:
            vm.region03 = formatedItems
            break
        }
      }, this.getRegionArticleListError)
    },
    // 獲取區域設置失敗
    getRegionArticleListError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },
    // 獲得目前的 Tab 項目名稱
    getCurrentTabName () {
      let tabName
      switch (this.tabIndex) {
        case 0:
          tabName = '大輪播圖'
          break;
        case 1:
          tabName = '焦點新聞'
          break;
        case 2:
          tabName = '右側新聞'
          break;
      }
      return tabName
    },
    // 獲得目前的 Tab 項目內容
    getCurrentTabContent () {
      let News
      switch (this.tabIndex) {
        case 0:
          News = this.region01
          break;
        case 1:
          News = this.region02
          break;
        case 2:
          News = this.region03
          break;
      }
      return News
    },
    // 請求更新分區資料
    saveRegionData () {
      const vm = this
      // 獲得新聞項目資料
      let NewsList = this.getCurrentTabContent()
      console.log('=> 執行檢查 NewsList:', NewsList)

      // 逐一檢查項目
      if (!checkFromData(vm, NewsList)) {
        return
      }

      // 獲得 Tab Name
      let tabName = this.getCurrentTabName()
      console.log('=> 執行儲存 Tab:', tabName)

      let setData = {
        name: tabName,
        items: NewsList
      }
      this.saving = true
      this.$root.apis.saveAreaSetting(this.tabIndex + 1, setData, this.processSaveSuccess, this.processSaveError)
    },
    processSaveSuccess () {
      let tabName = this.getCurrentTabName()
      this.$root.common.showSingleNotify(tabName + "儲存成功！")
      this.saving = false
    },
    processSaveError (_error) {
      console.error(_error)
      this.$root.common.showErrorNotify(_error.body.data)
      this.saving = false
    },
    // 請求發布分區資料
    releaseAreaSetting () {
      this.releasing = true
      this.$root.apis.releaseAreaSetting(this.tabIndex + 1, this.processReleaseSuccess, this.processReleaseError)
    },
    processReleaseSuccess () {
      let tabName = this.getCurrentTabName()
      this.$root.common.showSingleNotify(tabName + "發布成功！")
      this.releasing = false
    },
    processReleaseError (_error) {
      let tabName = this.getCurrentTabName()
      this.$root.common.showErrorNotify(_error.body.data)
      this.releasing = false
    },
    onSortClick () {
      this.currentTabContent = this.getCurrentTabContent()
      this.showSort = true
    },
    onSort (_sorted) {
      let vm = this
      switch (this.tabIndex) {
        case 0:
          vm.region01 = _sorted
          break;
        case 1:
          vm.region02 = _sorted
        case 2:
          vm.region03 = _sorted
          break;
      }
      this.showSort = false
    },
    // 自動帶入
    onFastImportNewsList (_news) {
      console.log('=> 接收自動帶入的新聞清單: ', _news)
      let News = _news.map(_article => {
        return formatImportNews(_article)
      })

      switch (this.tabIndex) {
        case 0:
          this.region01 = false
          this.region01 = News
          break;
        case 1:
          this.region02 = false
          this.region02 = News
          break;
        case 2:
          this.region03 = false
          this.region03 = News
          break;
      }
    }
  },
  components: {
    Layout,
    PageHeader,
    newsItem,
    Sort,
    FastImport
  },
};
</script>

<style lang="sass" scoped>
::v-deep.nav-tabs
  background: #e2e8f0
::v-deep.nav-tabs .nav-link
  font-weight: 400
  font-size: 15px
  padding: 0.8rem 2.5rem

.back-icon
  margin-right: 5px
  position: relative
  font-size: 14px
  top: 1.2px
.add-btn
  width: 100%
  border: dashed 1px

::v-deep.vue-dropzone
  padding: 0
::v-deep.dropzone-previews
  height: 170px

  .preview-img
    width: 100%
    height: 100%
    max-height: 170px
    object-fit: cover
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

.card
  margin-bottom: 2rem
  .form-group
    display: flex
    justify-content: space-start
    align-items: center
    flex-wrap: wrap
    gap: 14px
    .search-box
      position: relative
      #tip
        padding: 0 10px 0 35px
        background-repeat: no-repeat
        background-position: 10px 7px
      .fa-search
        position: absolute
        top: 30%
        margin: auto
    .del-btn
      flex: 1
      display: flex
      justify-content: flex-end
      align-items: center
      .btn
        padding: 0rem .3rem
        border-radius: 50px
::v-deep .tab-pane
  width: 100%

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 1rem 0
</style>